import styled from "styled-components";
import { Layout } from "antd";
import { NavLink } from "react-router-dom";

const { Sider, Content } = Layout;

export const LayoutStyled = styled(Layout)`
	height: 100vh;
`;

export const ContentStyled = styled(Content)`
	text-align: center;
	min-height: 120px;
	line-height: 120px;
`;

export const SiderStyled = styled(Sider)`
	width: ${(p) => (p.toggleMenu ? "214px" : "64px")};

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: ${(p) => (p.toggleMenu ? "14px 14px 14px 8px" : "14px  8px")};
	transition: width 0.2s ease-in-out;

	color: ${({ theme }) => theme.color.accent};
	background-color: ${({ theme }) => theme.color.bg_dark};

	//Icons
	& svg {
		min-width: 40px;
		min-height: 40px;
		padding: 2px;

		border-radius: 50%;
		transition: opacity 0.4s ease-in-out, background-color 0.4s ease-in-out;
	}
`;

export const SideMenu = styled.ul`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	gap: 10px;
	border-bottom: 1px solid #64d2ff;
`;

export const MenuItem = styled.li`
	display: inline-flex;
	flex-direction: row;

	padding: 0 4px;
	border-radius: 50%;

	//NavLink
	& a {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		gap: ${(p) => (p.toggleMenu ? "12px" : "30px")};

		&.active svg {
			background-color: white;
		}

		& span {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			padding: 9px;
			background-color: ${({ theme }) => theme.color.bg_dark};
			border-radius: 20px;
		}
	}

	& svg:hover,
	:focus {
		background-color: #ffffff30;
	}
`;

export const Logo = styled.button`
	display: flex;
	align-items: center;
	margin-bottom: 2rem;

	background-color: transparent;
	border: none;

	& p {
		color: white;
		margin-left: 12px;
	}
`;

export const Account = styled(NavLink)`
	margin-top: 1rem;
	display: flex;
	align-items: center;

	& p {
		margin-left: 12px;
	}
`;
