import styled from "styled-components";





const ButtonStyled = styled.button`
  padding: ${(props) => props.size === "large" ? "20px 40px" : "17px 25px" }   ;
  background: ${(props) => props.color ? props.color : "#0084E4" };
  box-shadow: ${(props) => props.boxShadow ? "0px 0px 100px 15px #0084E4" : "none"}; 
  border-radius: 50px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.img`
  width: ${(props) => props.size === "large" ? "55px" : "36px"};
  height: ${(props) => props.size === "large" ? "50px" : "36px"};
`;

const Title = styled.p`
  color: white;
  font-family: Inter Tight, Poppins, sans-serif;
  font-size:${(props) => props.size === "large" ? "40px" : "26px"}; 
  font-weight: 700;
`;




export {ButtonStyled, Icon, Title}