import { Link } from "react-router-dom";
import { Input, Form } from 'antd';
import styled from "styled-components";



const Container = styled.div`
	display: flex;
	height: 100vh;
	width: 100vw;
`;

const Content = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 5%;
	width: 100%;
	@media (width > 992px){
		width: 50%;
		padding : 0;
	}
`;

const FormContainer = styled.section`
	max-width: 400px;
	width: 100%;
`;

const Title = styled.h2`
	font-size: 40px;
	font-weight: 600;
	line-height: 1;
	margin-block: 15px;
	text-align: center;
`;

const Aside = styled.aside`
	display: none;
	@media (width > 992px){
		align-items: center;
		background: linear-gradient(227deg, rgba(255,106,106,1) 0%, rgba(53,221,255,1) 100%);
		display: flex;
		justify-content: center;
		width: 50%;
	}
`;

const Or = styled.p`
	font-size: 12px;
	color: #7b7b7b;
	margin: 12px 0;
	text-align: center;
`;
const FormArea = styled(Form)`
	width: 100%;
`;

// const Input = styled.input`
// 	font-size: 16px;
// 	margin-block: 15px 10px;
// 	padding: 11px;
// 	width: 100%;
// `;


const InputField = styled(Input)`
	// font-size: 16px;
	// margin-block: 15px 10px;
	// padding: 11px;
	width: 100%;
`;

const Privacy = styled.p`
	color: #7b7b7b;
	margin-block: 16px;
	text-align: center;
`;

const LoginLink = styled.p`
	color: rgba(255,10,106,1);
	margin-top: 16px;
	text-align: center;
`;

const ShowPass = styled.div`
	background: #fff0;
	border: none;
	border-radius: 50%;
	cursor: pointer;
	height: 18px;
	position: absolute;
	right: 11px;
	top: 8px;
	width: 18px;
	&:hover{
		background: #ccc;
	}
`;

const InputContainer = styled(Form.Item)`
	position: relative;
`;

const HideExplainMessage = styled.div`
	color: red;
	line-height: 0;
	margin-block: 15px;
`;

const ForgotPasswordLink = styled(Link)`
	display: block;
	color: blue;
	margin-block: 15px;
    text-align: center;
    text-decoration: underline;
`;

const LoginButton = styled.button`
	background: linear-gradient(227deg, rgba(255,106,106,1) 0%, rgba(53,221,255,1) 100%);
	border: none;
	border-radius: 15px;
	font-size: 15px;
	font-weight: 600;
	padding-block: 10px;
	width: 100%;
`;

export { Container, Content, FormContainer, Title, Aside, Or, FormArea, Privacy, 
		 LoginLink, ShowPass, InputContainer, HideExplainMessage, ForgotPasswordLink,
		 LoginButton, InputField };
