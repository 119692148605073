import Link from "antd/es/typography/Link";
import img from "../../../assets/images/Sign-img.jpg";
import {
	Container,
	Content,
	FormContainer,
	Title,
	Aside,
	Or,
	FormArea,
	InputField,
	Privacy,
	LoginLink,
} from "./SignUpPage.styled";

export function SignUpPage() {
	

	return (
		<Container>
            <Content>
                <FormContainer>
					<Title>Let`s get started by creating your free Teal account</Title>
					{/* google */}
					{/* linkedin */}
					<Or>OR</Or>
					<FormArea action="">
						<InputField type="email" placeholder="Email" />
					</FormArea>
					<Privacy>
						By signing up, I agree to the <Link>Terms of Use</Link> and <Link>Privacy Policy</Link>
					</Privacy>
					<LoginLink>
						Already have an account? <Link>Login</Link>
					</LoginLink>	
				</FormContainer>
			</Content>
			<Aside>
				<img src={img} alt="Worker" style={{width: "clamp(200px, 80%, 400px)"}} />
			</Aside>
			{/* <Logo></Logo> */}
			{/* <Feedback></Feedback> */}
		</Container>
	);
}
