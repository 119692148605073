import React from "react";
import {Container, Description, Icon, Title} from "./AdvantageCard.styled";



export function AdvantageCard(props) {
  return(
      <Container>
        <Icon src={props.icon} alt={props.alt}/>
        <Title>{props.title}</Title>
        <Description>{props.text}</Description>
      </Container>
  );
}