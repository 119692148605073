import React from "react";
import {SectionCreateCVStyled, Title, ContainerBtns, TitleQuest, ContainerQuest} from "./SectionCreateCV.styled";
import {Button} from "../Button/Button";
import iconBtn1 from "../../../../assets/icons/person-pin-outline.svg";
import iconBtn2 from "../../../../assets/icons/file-search-outlined.svg";
import {QuestionCard} from "./QuestionCard/QuestionCard";


const QuestionItem = [
  {id: "1", question: "Які переваги надає ваша платформа у створенні резюме?", answer: "Наша платформа надає зручний" +
      " конструктор резюме з різними шаблонами, які допоможуть вам виділитися серед конкурентів. Ми також пропонуємо" +
      " вказівки щодо дизайну, важливих розділів і ключових навичок, щоб дати вам найкращі шанси на успіх."},
  {id: "2", question: "Як працює пошук роботи на вашій платформі?", answer: "Наша платформа забезпечує простий у " +
      "використанні пошук роботи, де ви можете фільтрувати результати за різними критеріями, включаючи посаду, досвід," +
      " місцезнаходження та необхідні технології."},
  {id: "3", question: "Чи можу я зберегти кілька версій свого резюме на вашій платформі?", answer: "Так, ви можете" +
      " зберегти кілька версій свого резюме на нашій платформі. Це дозволяє вам адаптувати своє резюме відповідно до " +
      "різних вакансій або демонструвати різні набори навичок і досвіду залежно від потреб роботодавця."},
  {id: "4", question: "Як мої дані зберігаються конфіденційними?", answer: "Збереження вашої конфіденційності та" +
      " безпеки ваших даних є нашим пріоритетом. Ми діємо згідно з чинним законодавством, що регулює захист даних та приватність."},
];

export function SectionCreateCV () {
  return(
    <SectionCreateCVStyled>
      <Title>Дізнайтеся більше інформації та приєднуйтеся до нас!</Title>
      <ContainerBtns>
        <Button icon={iconBtn1}
                alt={"icon"}
                text={"Роботодавцям"}
                size={"large"}
        />
        <Button icon={iconBtn2}
                alt={"icon"}
                text={"Кандидатам"}
                size={"large"}
                color={"#000000"}/>
      </ContainerBtns>
      <Button icon={iconBtn2}
              alt={"icon"}
              text={"Створіть власне CV"}
              size={"large"} boxShadow={true}
      />
      <ContainerQuest>
        <TitleQuest>·Відповіді на поширені запитання·</TitleQuest>
        <QuestionCard questions={QuestionItem}
        />
      </ContainerQuest>
    </SectionCreateCVStyled>
  )
}