import React from "react";
import {Container, SectionAdvantagesStyled, Title} from "./SectionAdvantages.styled";
import {AdvantageCard} from "./AdvantageCard/AdvantageCard";
import valuableIcon from "../../../../assets/images/SectionAdvantage/valuable.png";
import vacancies1 from  "../../../../assets/images/SectionAdvantage/vacancies2.png";
import personalizationIcon from "../../../../assets/images/SectionAdvantage/personalization.png";
import resumeIcon from "../../../../assets/images/SectionAdvantage/resume.png";


const Advantages = [
  {id: "1", icon: valuableIcon, alt: "valuable", title: "Цінні\n ресурси та поради", text: "Отримайте корисні поради та" +
      " ресурси, які допоможуть вам досягти успіху в пошуку роботи в ІТ."},
  {id: "2", icon: vacancies1, alt: "vacancies", title: "Широкий вибір вакансій", text: "Шукайте роботу в IT-сфері серед" +
      " тисяч актуальних вакансій. Ми надаємо доступ до свіжих вакансій, щоб допомогти вам знайти ідеальну роботу."},
  {id: "3", icon: personalizationIcon, alt: "personalization", title: "Персоналізація\n під ваші потреби",
    text: "Налаштуйте своє резюме відповідно до роботодавців і виділяйтеся серед інших кандидатів." +
      " Створіть унікальне резюме, що відображає ваш досвід і професіоналізм."},
  {id: "4", icon: resumeIcon, alt: "resume", title: "Професійні резюме за лічені хвилини", text: "Створюйте привабливі" +
      " резюме всього за кілька простих кроків. Наш зручний конструктор допоможе вам швидко виділитися серед інших кандидатів."},
];



export function SectionAdvantages () {
  return(
    <SectionAdvantagesStyled>
        <Title>·Наші переваги·</Title>
        <Container>
          {
            Advantages.map((card) =>
              <AdvantageCard key={card.id}
                             icon={card.icon}
                             title={card.title}
                             text={card.text}
              />
            )
          }
        </Container>
    </SectionAdvantagesStyled>
  )
}