export default function validateForm({email, password}){

    let error = {};

    if(!email){
        error.email = 'Email is required to log in';
    }

    if(!password){
        error.password = 'Password is required to log in';
    }   
    return error;
}