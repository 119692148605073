import React from "react";
import {FooterStyled, Container1, Container2} from "./Footer.styled";
import {ListLinks} from "./ListLinks/ListLinks";
import {ExternalLinks} from "./ExternalLinks/ExternalLinks";
import AppStore from "../../../../assets/images/btn_download_app_store.png";
import GooglePlay from "../../../../assets/images/btn_download_google_play.png";
import InstagramIcon from "../../../../assets/icons/instagram.svg";
import FacebookIcon from "../../../../assets/icons/fbIcon.svg";
import YoutubeIcon from "../../../../assets/icons/youtubeIcon.svg";
import TwitterIcon from "../../../../assets/icons/twitterIcon.svg";
import MediumIcon from "../../../../assets/icons/mediumIcon.svg";
import DribbleIcon from "../../../../assets/icons/dribbleIcon.svg";


const FeaturesItems = [
  {id: "1", name: "Claims", link: "#"},
  {id: "2", name: "Giveback", link: "#"},
  {id: "3", name: "Policy 2.0", link: "#"},
];

const ResoursesItems = [
  {id: "1", name: "FAQ", link: "#"},
  {id: "2", name: "Де Ми Живемо", link: "#"},
];

const CompanyItems = [
  {id: "1", name: "Приєднуйтесь до Команди", link: "#"},
  {id: "2", name: "Юридичний Матеріал", link: "#"},
  {id: "3", name: "Політика Конфіденційності", link: "#"},
  {id: "4", name: "Умови Використання", link: "#"},
];

const AppItems = [
  {id: "1", image: AppStore, alt: "AppStore",  link: "#"},
  {id: "2", image: GooglePlay, alt: "GooglePlay", link: "#"},
];

const FollowItems = [
  {id: "1", image: InstagramIcon, alt: "InstagramIcon", link: "#"},
  {id: "2", image: FacebookIcon, alt: "FacebookIcon", link: "#"},
  {id: "3", image: TwitterIcon, alt: "TwitterIcon", link: "#"},
  {id: "4", image: YoutubeIcon, alt: "YoutubeIcon", link: "#"},
  {id: "5", image: MediumIcon, alt: "MediumIcon", link: "#"},
  {id: "6", image: DribbleIcon, alt: "DribbleIcon", link: "#"},
];


export function Footer () {
  return(
    <FooterStyled>
      <Container1>
        <ListLinks
          title={'Особливості'}
          items={FeaturesItems}
        />
        <ListLinks
          title={'Ресурси'}
          items={ResoursesItems}
        />
        <ListLinks
          title={'Компанія'}
          items={CompanyItems}
        />
      </Container1>
      <Container2>
        <ExternalLinks
          title={"Завантажте наш додаток"}
          items={AppItems}
        />
        <ExternalLinks
          title={"Слідуй за нами"}
          items={FollowItems}
        />
      </Container2>
    </FooterStyled>
  );
}