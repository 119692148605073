import React, { useEffect } from "react";
import { useNavigate } from "react-router";

export function NotFound() {
	const navigate = useNavigate();
	useEffect(() => {
		const timer = setTimeout(() => {
			navigate("/", { replace: true });
		}, 5000);

		return () => clearTimeout(timer);
	}, [navigate]);

	return (
		<>
			<h1> Page Not Found</h1>
			<p> You will be redirected to HomePage in 5 sec</p>
		</>
	);
}
