import styled from "styled-components";



const ListStyled = styled.ul`
 
`;

const Container = styled.div`

`;

const Title = styled.p`
  font-family: Inter Tight, Poppins, sans-serif;
  color: white;
  text-transform: uppercase;
  font-size: 24px;
  padding-bottom: 44px;
`;

const ListItem = styled.li`
  padding-bottom: 40px;
  

`;
const Link =styled.a`
  font-family: Inter Tight, Poppins, sans-serif;
  font-size: 20px;
  line-height: 24px;
  color: white;
`;



export {ListStyled, ListItem, Link, Container, Title}