export const theme = {
	color: {
		accent: "#64d2ff", //blue-dark
		accent_light: "#5AC8FA", // blue-light

		text_white: "#ffffff",
		text_dark: "#1C1C1E", //black
		text_dark_teal: "#2C4755",
		text_light_grey: "#8E8E93", //links or disabled info

		bg_dark: "#1C1C1E",

		// text_dark: "#697A8D", //grey

		// bg: "##ffffff",
		// bg_hover: "#6359710A",
		// bg_blur: "rgba(255, 255, 255, 0.4)",
	},

	fontWeights: {
		normal: 400,
		bold: 600,
	},

	lineHeights: 1.4,

	borders: {
		none: "none",
		normal: "1px solid",
	},

	transition: "200ms ease-in-out",

	breakpoints: {
		sm: "480px", //? need it or not ?
		md: "768px",
		lg: "1200px",
	},
};
