import styled from "styled-components";



const ExternalLinksStyled = styled.div`

`;

const Title = styled.p`
  font-family: Inter Tight, Poppins, sans-serif;
  color: white;
  text-transform: uppercase;
  font-size: 24px;
  padding-bottom: 24px;
`;

const ListLinks = styled.ul`
  display: flex;
  justify-content: space-around;
`;

const Item = styled.li`
  padding: 0 15px 0 10px;
`;

const Image = styled.img`

`;

const Link = styled.a`

`;

export {ExternalLinksStyled, Title, ListLinks, Item, Image, Link}