import { AiOutlineFileText, AiOutlineHome, AiOutlineProject, AiOutlineUser } from "react-icons/ai";
import { BiBriefcase } from "react-icons/bi";
import { RiContactsBook2Line } from "react-icons/ri";

export const navRoutes = [
	{ path: "/", icon: AiOutlineHome },
	{ path: "/work-history", icon: AiOutlineUser },
	{ path: "/resume-builder", icon: AiOutlineFileText },
	{ path: "/job-tracker", icon: AiOutlineProject },
	{ path: "/contacts", icon: RiContactsBook2Line },
	{ path: "/companies", icon: BiBriefcase },
];
