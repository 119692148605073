import styled from "styled-components";
import backgroundImg from "../../../../assets/images/secBg.png";


const SectionCreateCVStyled = styled.div`
  background: url(${backgroundImg}) no-repeat;
  padding-bottom: 190px;
  
  button:nth-child(3) {
    margin: 0 auto;
  }
  
  
`;

const Title = styled.p`
  font-family: Inter Tight, Poppins, sans-serif;
  font-size: 48px;
  font-weight: 500;
  text-align: center;
  padding: 155px 0 65px 0;
`;

const ContainerBtns = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 752px;
  
  button:nth-child(1) {
    margin-right: 30px;
  }

  button:nth-child(2) {
    width: 416px;
  }
`;

const TitleQuest = styled.h2`
  font-family: Inter Tight, Poppins, sans-serif;
  font-size: 64px;
  font-weight: 900;
  white-space: pre-wrap;
  text-decoration: underline #0084E4;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 95px;
`;

const ContainerQuest = styled.div`
  max-width: 1272px;
  margin: 0 auto;
  padding-top: 740px;
  
  div:last-child {
    padding-bottom: 0;
  }
`;


export {SectionCreateCVStyled, Title, ContainerBtns, TitleQuest, ContainerQuest}