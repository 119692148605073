import React from "react";
import logo from "../../../..//assets/images/Logo.png";
import {Navigation} from "./Navigation/Navigation";
import headerImg from "../../../../assets/images/HeaderImg.png"
import {Container, Content, HeaderStyled, Title, SubTitle, BtnsContainer} from "./Header.styled";
import {Button} from "../Button/Button";
import searchIcon from "../../../../assets/icons/file-search-outlined.svg";
import personIcon from "../../../../assets/icons/person-pin-outline.svg"


export function Header() {
  return(
   <HeaderStyled>
     <Navigation logo={logo}
                 alt={"logo"}
                 linkName1={"F.A.Q"}
                 linkName2={"Авторизація"}
     />
     <Container>
      <img src={headerImg} alt={"headerImg"}/>
      <Content>
        <Title>Ваше майбутнє починається тут!</Title>
        <SubTitle>IT-фахівці та компанії в одному місці</SubTitle>
        <BtnsContainer>
          <Button icon={personIcon} altName={"iconPerson"} text={"Роботодавцям"}/>
          <Button icon={searchIcon} altName={"iconSearch"} color={"#000000"} text={"Кандидатам"}/>
        </BtnsContainer>
      </Content>
     </Container>
   </HeaderStyled>
  );
}