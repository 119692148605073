import React from "react";
import {AboutUsCardStyled, Description, Image} from "./AboutUsCard.styled";

export function AboutUsCard (props) {
  return(
    <AboutUsCardStyled rowReverse={props.rowReverse}>
      <Image src={props.picture} alt={props.alt}/>
      <Description textStart={props.textStart}>{props.text}</Description>
    </AboutUsCardStyled>
  )
}