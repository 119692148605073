import styled from "styled-components";

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  height: 106px;
  margin-bottom: 25px;
`;

const LogoLink = styled.a`
  
`;

const Link = styled.a`
  font-family: Inter Tight, Poppins, sans-serif;
  font-size: 24px;
  font-weight: 500;
`;

const Links = styled.ul`
  display: flex;
  align-items: center;
 
  
  li:nth-child(2) {
    margin: 0 80px;
  }
`;

export { Nav, LogoLink, Links, Link};