import React from "react";
import {Header} from "./Header/Header";
import {LandingPageStyled} from "./LandingPage.styled";
import {SectionAdvantages} from "./SectionAdvantages/SectionAdvantages";
import {SectionAboutUs} from "./SectionAboutUs/SectionAboutUs";
import {SectionCreateCV} from "./SectionCreateCV/SectionCreateCV";
import {Footer} from "./Footer/Footer";

export function LandingPage() {
  return (
    <LandingPageStyled>
      <Header/>
      <SectionAdvantages/>
      <SectionAboutUs/>
      <SectionCreateCV/>
      <Footer/>
    </LandingPageStyled>
  );
}