import styled from "styled-components";



const FooterStyled = styled.div`
  background: black;
  padding-top: 55px;
  padding-bottom: 145px;
  display: flex;
  justify-content: center;
`;

const Container1 = styled.div`
  display: flex;
  div:nth-child(2) {
    margin: 0 80px 0 80px;
  }
`;

const Container2 = styled.div`
  margin-left: 80px;
  
  div:first-child {
    padding-bottom: 55px; 
  }
  
`;

export {FooterStyled, Container1, Container2}
