import React from "react";
import {SectionAboutUsStyled, Title} from "./SectionAboutUs.styled";
import {AboutUsCard} from "./AboutUsCard/AboutUsCard";
import pic1 from "../../../../assets/images/SectionAboutUs/Group 46.png";
import pic2 from "../../../../assets/images/SectionAboutUs/Group 45.png";
import pic3 from "../../../../assets/images/SectionAboutUs/Group 44.png";

const AboutUs = [
  {id: "1", picture: pic1, alt: "picture", text: "Ми надаємо унікальний і комплексний онлайн-сервіс для складання резюме" +
      " та пошуку роботи в IT-сфері. Наша платформа допомагає шукачам роботи в ІТ-галузі представити свої навички та" +
      " досвід у найкращому світлі, а також полегшує та ефективний пошук відповідних вакансій." },
  {id: "2", picture: pic2, alt: "picture", rowReverse: "row-reverse", textStart: "start", text: "Наша платформа" +
      " використовує передові технології та інструменти для забезпечення високої продуктивності та надійності нашого" +
      " сервісу. Наша платформа оптимізована для швидкого завантаження сторінок і зручної навігації для комфортного" +
      " використання. Ми також гарантуємо безпеку ваших персональних даних і забезпечуємо конфіденційність всієї" +
      " інформації, пов'язаної з пошуком роботи." },
  {id: "3", picture: pic3, alt: "picture", text: "Ми пишаємося тим, що наш онлайн-сервіс стає надійним партнером" +
      " для тих, хто прагне успішно розвиватися в IT-сфері. Незалежно від того, початківець ви чи досвідчений" +
      " професіонал, наша платформа надасть вам усі інструменти та ресурси, необхідні для подальшого розвитку та" +
      " успіху у вашій кар’єрі." },
];



export function SectionAboutUs () {
  return(
    <SectionAboutUsStyled>
      <Title>·про нас·</Title>
      {
        AboutUs.map((card) =>
        <AboutUsCard  key={card.id}
                      picture={card.picture}
                      alt={card.alt}
                      textStart={card.textStart}
                      text={card.text}
                      rowReverse={card.rowReverse}
        />
        )
      }
    </SectionAboutUsStyled>
  )
}