import React from "react";
import {ButtonStyled, Icon, Title} from "./Button.styled";




export function Button(props) {

  return(
    <ButtonStyled color={props.color} size={props.size} boxShadow={props.boxShadow}>
      <Icon src={props.icon} alt={props.altName} size={props.size}/>
      <Title size={props.size}>{props.text}</Title>
    </ButtonStyled>
  );
}