import React from "react";
import {Container, Link, ListItem, ListStyled, Title} from "./List.styled";


export function ListLinks (props) {

  return(
    <Container>
      <Title>{props.title}</Title>
      <ListStyled>
        {
          props.items.map((item) =>
            <ListItem key={item.id}>
              <Link href={item.link}>{item.name}</Link>
            </ListItem>
          )
        }
      </ListStyled>
    </Container>
  )

}




