import React from "react";
import {Description, QuestionCardStyled, Title, Container, Item} from "./QuestionCard.styled";


export function QuestionCard (props) {

  return(
    <QuestionCardStyled>
     <Container>
       {
         props.questions.map((item) =>
         <Item key={item.id}>
          <Title>{item.question}</Title>
           <Description>{item.answer}</Description>
         </Item>
         )
       }
     </Container>
    </QuestionCardStyled>
  );
}