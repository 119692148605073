import { Outlet } from "react-router-dom";
import { BiMessageError } from "react-icons/bi";
import { Button } from "antd";

import Sidebar from "./Sidebar";
import { ContentStyled, LayoutStyled } from "./SharedLayout.styled";

//
//? done with Layout, lazy loading
//! need to add loader to Suspense
//! need to add private routes
//

export function SharedLayout() {
	return (
		<LayoutStyled hasSider>
			<Sidebar />
			<ContentStyled>
				<Outlet />
				{/* //fix: styles into .styled.js file */}
				<Button
					style={{
						position: "fixed",
						bottom: "30px",
						right: "30px",
						width: "46px",
						height: "46px",
						padding: "6px 5px 4px",
						display: "inline-block",
					}}>
					<BiMessageError style={{ width: "36px", height: "36px" }} />
				</Button>
			</ContentStyled>
		</LayoutStyled>
	);
}
