import styled from "styled-components";



const SectionAboutUsStyled = styled.div`
  padding-bottom: 65px;
 div:nth-child(3) {
   padding: 185px 0 210px 0;
 }
`;

const Title = styled.h2`
  font-family: Inter Tight, Poppins, sans-serif;
  font-size: 64px;
  font-weight: 900;
  text-decoration: underline #0084E4;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 130px;
`;




export {SectionAboutUsStyled, Title}