import { Suspense } from "react";
import { lazily } from "react-lazily";
import { Route, Routes } from "react-router";

import { SharedLayout, NotFound, MyAccount, Companies, Contacts } from "components";

import GlobalStyles from "styles/GlobalStyles";
import { theme } from "styles/styles";
import { ThemeProvider } from "styled-components";
import {LandingPage} from "./components/pages/LandingPage/LandingPage";

const {  HomePage, JobTracker,  ResumeBuilder, WorkDetails, WorkHistory, SignUpPage, SignInPage } = lazily(() =>
	import("components")
);

function App() {
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyles />
			<Suspense fallback={<p>Loader</p>}>
				<Routes>
					<Route path="/register" element={<SignUpPage />} />
					<Route path="/login" element={<SignInPage />} />
          <Route path="/landing-page" element={<LandingPage/>} />
					<Route path="/" element={<SharedLayout />}>
						<Route index element={<HomePage />} />
						<Route path="my-account" element={<MyAccount />} />
						<Route path="work-history" element={<WorkHistory />} />
						<Route path="work-history/:id" element={<WorkDetails />} />
						<Route path="resume-builder" element={<ResumeBuilder />} />
						<Route path="job-tracker" element={<JobTracker />} />
						<Route path="contacts" element={<Contacts />} />
						<Route path="companies" element={<Companies />} />

						<Route path="*" element={<NotFound />} />
					</Route>
				</Routes>
			</Suspense>
		</ThemeProvider>
	);
}

export default App;
