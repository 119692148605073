import React from "react";
import {ExternalLinksStyled, Image, Item, Link, ListLinks, Title} from "./ExternalLinks.styled";


export function ExternalLinks (props) {
  return (
    <ExternalLinksStyled>
      <Title>{props.title}</Title>
      <ListLinks>
        {
          props.items.map((item) =>
          <Item key={item.id}>
            <Link href={item.link}>
              <Image src={item.image} alt={item.alt}/>
            </Link>
          </Item>
          )
        }
      </ListLinks>
    </ExternalLinksStyled>
  )
}