import styled from "styled-components";


const AboutUsCardStyled = styled.div`
  max-width: 1272px;
  margin: 0 auto;
  display: flex;
  flex-direction: ${(props) => props.rowReverse ? props.rowReverse : "row"};
  align-items: center;
  justify-content: space-between;
`;

const Image = styled.img`
  
`;

const Description = styled.div`
  font-family: Inter Tight, Poppins, sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  text-align: ${(props) => props.textStart ? props.textStart : "right"};
  max-width: 530px;
  :first-letter {
    color: #4AB7D9;
    font-size: 50px;
    font-weight: 600;
  }
`;

export {AboutUsCardStyled, Image, Description}