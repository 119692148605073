import React from "react";
import {Button} from "./Button/Button";
import {
  Nav,
  LogoLink,
  Links,
  Link
} from "./Navigation.styled";


export function Navigation(props) {
  return (
  <Nav>
    <LogoLink href={"#"}> <img src={props.logo} alt={props.alt}/> </LogoLink>
    <Links>
      <li><Link href={"#"}>{props.linkName1}</Link></li>
      <li><Link href={"#"}>{props.linkName2}</Link></li>
      <li><Button text={"Реєстраіція"}/></li>
    </Links>
  </Nav>
  );
}





