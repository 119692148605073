import styled from "styled-components";
import backImg from "../../../../assets/images/SectionAdvantage/bgSectionAdvantages.png"



const SectionAdvantagesStyled = styled.div`
  background: url(${backImg}) no-repeat -15px -120px;
`;

const Title = styled.h2`
  font-family: Inter Tight, Poppins, sans-serif;
  font-size: 64px;
  font-weight: 900;
  text-decoration: underline #0084E4;
  text-transform: uppercase;
  text-align: center;
  padding: 165px 0 115px 0;
`;

const Container = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 245px;
  
  div:nth-child(2){
    margin-bottom: 47px;
  }
  div:nth-child(2) img {
    padding-bottom: 46px;
  }
`;





export {SectionAdvantagesStyled, Title, Container}
