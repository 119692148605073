import styled from "styled-components";


const HeaderStyled = styled.header`
  max-width: 1330px;
  margin: 0 auto;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div`
  
`;

const Title = styled.h2`
  font-family: Inter Tight, Poppins, sans-serif;
  font-size: 97px;
  font-weight: 900;
  text-align: right;
  line-height: 90px;
  padding-bottom: 30px;
`;

const SubTitle = styled.p`
  font-family: Inter Tight, Poppins, sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  color: #4AB7D9;
  text-align: right;
`;

const BtnsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  
  button:nth-child(2) {
    margin-left: 50px;
    width: 270px;
  }
`;


export {HeaderStyled, Container, Content, Title, SubTitle, BtnsContainer}