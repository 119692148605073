import styled from "styled-components";


const ButtonStyled = styled.button`
  //width: 117px;
  padding: 12px 20px;
  height: 54px;
  border: none;
  border-radius: 7px;
  background: #4AB7D9;
  color: white;
  font-family: Inter Tight, Poppins, sans-serif;
  font-size: 24px;
  font-weight: 500;
`;


export {ButtonStyled}