import styled from "styled-components";


const LandingPageStyled = styled.div`
  max-width: 1512px;
  padding-top: 50px;
  margin: 0 auto;
`;



export {LandingPageStyled}