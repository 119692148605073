import styled from "styled-components";


const Container = styled.div`
  max-width: 445px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled.img`
  padding-bottom: 23px;
`;

const Title = styled.h2`
  font-family: Inter Tight, Poppins, sans-serif;
  font-weight: 900;
  font-size: 36px;
  color: #0084E4;
  text-transform: uppercase;
  text-align: center;
  line-height: 37px;
  white-space: pre-wrap;
  margin: 6px;
`;

const Description = styled.p`
  font-family: Inter Tight, Poppins, sans-serif;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  line-height: 32px;
  margin: 5px;
`;




export {Container, Icon, Title, Description}