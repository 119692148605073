import React, { useState, useRef, useEffect } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Input, Form, Space } from 'antd';
import validateForm from "./validateForm";
import HideIcon from "../../../assets/icons/hide.png";
import img from "../../../assets/images/Sign-img.jpg";
import {
	Container,
	Content,
	FormContainer,
	Title,
	Aside,
	Or,
	// Form,
	FormArea,
    ShowPass,
    InputContainer,
	HideExplainMessage,
	ForgotPasswordLink,
	LoginButton,
} from "./SignUpPage.styled";

export function SignInPage() {

	const emailRef = useRef();
	const passwordRef = useRef();
	const [emailError, setEmailError] = useState(null);
	const [passwordError, setPasswordError] = useState(null);
    const [passwordVisible, setPasswordVisible] = useState(false);

	useEffect(() => {
		console.log(emailError);
		console.log(passwordError);
	}, [passwordError, emailError])


	const handleClick = (e) => {
        e.preventDefault();
        setPasswordVisible(currentValue => !currentValue);
    }

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log('1');

		setEmailError(null);
		setPasswordError(null);
		const validateError = validateForm({email: emailRef.current?.input.value || 
											null, password: passwordRef.current?.input.value || null});
		if(Object.keys(validateError).length !== 0){
			if(validateError["password"]){
				setPasswordError(validateError.password);
			}
			if(validateError["email"]){
				setEmailError(validateError.email);
			}
			return;
		}
		setEmailError(null);
		setPasswordError(null);
	}

	return (
		<Container>
			<Content>
				<FormContainer>
					<Title>Login</Title>
					
					<FormArea onSubmit={(e) => handleSubmit(e)}>
                        <InputContainer>
						    <Input type="email" placeholder="Email" ref={emailRef}/>
							{
								emailError && 
                            	<HideExplainMessage>{emailError}</HideExplainMessage>
							}
                        </InputContainer>
						<InputContainer>
							<Input type={passwordVisible ? "text" : "password"} placeholder="Password" ref={passwordRef}/>
							<ShowPass onClick={e => handleClick(e)}>
								<img src={HideIcon} alt="hide" style={{width: '100%'}}/>
							</ShowPass>
							{
								passwordError && 
								<HideExplainMessage>{passwordError}</HideExplainMessage>
							}
						</InputContainer>
						<ForgotPasswordLink>Forgot Password?</ForgotPasswordLink>
						<LoginButton type="sumbit" onClick={(e) => handleSubmit(e)}>Sign in</LoginButton>
						<Or>OR</Or>
						{/* google */}
						{/* linkedin */}
					</FormArea>
				</FormContainer>
			</Content>
			<Aside>
				<img src={img} alt="Worker" style={{width: "clamp(200px, 80%, 400px)"}} />
			</Aside>
			{/* <Logo></Logo> */}
			{/* <Feedback></Feedback> */}
		</Container>
	);
}
