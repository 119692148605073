import styled from "styled-components";



const QuestionCardStyled = styled.div`
  padding-bottom: 50px;
  margin: 0 auto;
  
`;

const Title = styled.p`
  font-family: Inter Tight, Poppins, sans-serif;
  font-size: 48px;
  font-weight: 500;
  line-height: 65px;
  padding-bottom: 8px;
`;

const Description = styled.p`
  font-family: Inter Tight, Poppins, sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #306778;
  text-align: left;
  line-height: 32px;
  max-width: 1096px;
`;

const Container = styled.ol`
  list-style: decimal;
  li::marker {
    font-family: Inter Tight, Poppins, sans-serif;
    font-size: 48px ;
    font-weight: 500;
  }
`;

const Item = styled.li`
  padding-bottom: 50px;
`;





export {QuestionCardStyled, Title, Description, Container, Item}