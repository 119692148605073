import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { BiUser } from "react-icons/bi";

import { navRoutes } from "routes";

import { Account, Logo, MenuItem, SideMenu, SiderStyled } from "./SharedLayout.styled";

export default function Sidebar() {
	const [toggleMenu, setToggleMenu] = useState(false);
	const [showTimeout, setShowTimeout] = useState(true);
	const [hover, setHover] = useState(null);

	//fix: this will be user photo from API
	const src = null;

	useEffect(() => {
		let timeoutId;

		if (toggleMenu) {
			timeoutId = setTimeout(() => setShowTimeout(true), 200);
		} else {
			clearTimeout(timeoutId);
			setShowTimeout(false);
		}
	}, [toggleMenu]);

	function handleToggleMenu(e) {
		if (e.currentTarget.href === undefined) {
			setToggleMenu((prev) => !prev);
		} else {
			setToggleMenu(false);
		}
	}

	function handleMouseOver(e) {
		setHover(e.currentTarget.firstChild.ariaLabel);
	}

	function handleMouseLeave() {
		setHover(null);
	}

	function textTransform(path) {
		return path === "/"
			? "Home"
			: path
					.slice(1)
					.split("-")
					.map((e) => e[0].toUpperCase() + e.slice(1))
					.join(" ");
	}

	return (
		<SiderStyled as="nav" toggleMenu={toggleMenu}>
			<Logo type="button" onClick={handleToggleMenu}>
				<img
					src="#"
					alt=""
					style={{
						minWidth: "38px",
						minHeight: "38px",
						border: "none",
						backgroundColor: "white",
					}}
				/>
				{showTimeout && <p style={{ color: "white" }}>Job Seeker</p>}
			</Logo>

			<SideMenu>
				{navRoutes.map(({ path, icon: Icon }) => {
					const name = textTransform(path);

					return (
						<MenuItem key={path}>
							<NavLink to={path} onClick={handleToggleMenu}>
								<div onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
									<Icon aria-label={name} />
								</div>
								{showTimeout && <p>{name}</p>}
								{!toggleMenu && hover === name && <span>{name}</span>}
							</NavLink>
						</MenuItem>
					);
				})}
			</SideMenu>
			<Account to="my-account" onClick={handleToggleMenu}>
				{src ? (
					<img src={src} alt="User" className="user" />
				) : (
					<BiUser
						style={{
							fill: "white",
							backgroundColor: "#64d2ff",
						}}
					/>
				)}
				{showTimeout && <p>Account</p>}
			</Account>
		</SiderStyled>
	);
}
